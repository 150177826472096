
import React from 'react';
import 'tachyons';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import ScrollTop from './components/ScrollTop';
import Client from 'shopify-buy';
const client = Client.buildClient({
  storefrontAccessToken: 'db372a0d24632e0a4d1d251614846a41',
  domain: 'shoparzel.myshopify.com'
});
ReactDOM.render(
  <BrowserRouter>
  <ScrollTop>
    <App client={client} />
    </ScrollTop>
  </BrowserRouter>,
  document.getElementById('root')
);